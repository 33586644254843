import { getErrorCode, getDateByFormat } from '@/api/common';
import store from '@/store/store';

import { use } from 'echarts/core';
import { LineChart, PieChart, BarChart, GaugeChart } from 'echarts/charts';
import { LegendComponent, GridComponent, ToolboxComponent, DataZoomComponent, TooltipComponent, DatasetComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([
	LegendComponent,
	GridComponent,
	ToolboxComponent,
	DataZoomComponent,
	TooltipComponent,
	LineChart,
	CanvasRenderer,
	DatasetComponent,
	PieChart,
	BarChart,
	GaugeChart
]);

export default {
	data() {
		return {
			palette: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
		};
	},
	methods: {
		initChart(slaData) {
			let values = [];
			let days = [];
			console.warn('debug init chart', slaData);
			slaData.forEach((dateGroup) => {
				days.push(dateGroup.datevalue);
				let totalDateOk = 0;
				let totalDateError = 0;
				dateGroup.group.forEach((sla) => {
					if (sla.docsok + sla.docserror > 0) {
						totalDateOk += sla.docsok;
						totalDateError += sla.docserror;
					}
				});
				if (totalDateOk + totalDateError > 0) {
					let percent = (totalDateOk / (totalDateOk + totalDateError)) * 100;
					values.push(percent.toFixed(2));
				} else {
					values.push(0);
				}
			});

			let option = {};
			let chartTitle = this.userProperties.organizationname;

			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let tooltip = {};

			data = { values: values, days: days };
			series.push({
				name: chartTitle,
				type: 'line',
				xAxisIndex: axis,
				smooth: true,
				emphasis: {
					focus: 'series'
				},
				label: {
					formatter: '{@score} %'
				},
				data: data.values
			});

			axis++;

			xAxis.push({
				type: 'category',
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: data.days
			});

			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				valueFormatter: (value) => (value != null ? value + '%' : '- %')
			};

			option['legend'] = { data: [chartTitle] };
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent('%');
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			console.log('debug init option', option, JSON.stringify(option), store.getters.getParameterStatus);
			console.log('test: option set', option);
			return option;
		},
		initChartGroup(slaDataByGroup, type) {
			//let groupTypes = [];
			let values = {};
			let days = {};
			let idField = null;
			let nameField = null;
			let allDates = [];
			switch (type) {
				case 'area':
					idField = 'pmareaid';
					nameField = 'areaname';
					//groupTypes = store.getters.getAreas;
					break;
				case 'datasource':
					idField = 'pmdatasourcetypeid';
					nameField = 'datasourcetypename';
					//groupTypes = store.getters.getDatasourcetype;
					break;
				default:
					break;
			}
			console.debug('debug init chart ' + type, slaDataByGroup);

			slaDataByGroup.forEach((typeGroup) => {
				days[typeGroup[idField]] = [];
				values[typeGroup[idField]] = [];
				typeGroup.group.forEach((dateGroup) => {
					if (!allDates.includes(dateGroup.datevalue)) allDates.push(dateGroup.datevalue);
					days[typeGroup[idField]].push(dateGroup.datevalue);
					let totalDateOk = 0;
					let totalDateError = 0;
					dateGroup.group.forEach((sla) => {
						if (sla.docsok + sla.docserror > 0) {
							totalDateOk += sla.docsok;
							totalDateError += sla.docserror;
						}
					});
					if (totalDateOk + totalDateError > 0) {
						let percent = (totalDateOk / (totalDateOk + totalDateError)) * 100;
						values[typeGroup[idField]].push(percent.toFixed(2));
					} else {
						values[typeGroup[idField]].push(0);
					}
				});
			});
			console.debug('debug init chart ' + type, days, values, allDates);
			slaDataByGroup.forEach((typeGroup) => {
				if (allDates.length != days[typeGroup[idField]].length) {
					allDates.forEach((date, i) => {
						if (!days[typeGroup[idField]].includes(date)) {
							days[typeGroup[idField]] = days[typeGroup[idField]].toSpliced(i, 0, date);
							values[typeGroup[idField]] = values[typeGroup[idField]].toSpliced(i, 0, null);
						}
					});
				}
			});

			let option = {};

			console.debug('debug: days and values', days, values);
			let groupTitles = [];

			let data = {};
			let series = [];
			let yAxis = [];
			let tooltip = {};

			slaDataByGroup.forEach((typeGroup) => {
				//let element = groupTypes.find((element) => element[idField] == typeGroup[idField]);
				data = { values: values[typeGroup[idField]], days: days[typeGroup[idField]] };
				//element ? groupTitles.push(element.name) : groupTitles.push(type + ' SLA ' + typeGroup[idField]);
				console.log('debug group chart', typeGroup, nameField, typeGroup[nameField]);
				groupTitles.push(typeGroup[nameField]);
				series.push({
					name: typeGroup[nameField],
					id: typeGroup[nameField] + '_' + typeGroup[idField],
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score} %'
					},
					data: data.values
				});
			});
			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				formatter: this.setTooltipFormatter(),
				valueFormatter: (value) => (value != null ? value + '%' : '- %')
			};
			option['legend'] = { data: groupTitles };
			option['series'] = series;
			option['xAxis'] = {
				type: 'category',
				show: true,
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: allDates
			};
			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent('%');
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['grid'] = {
				top: groupTitles.length > 5 ? '20%' : '10%'
			};
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			console.log('test: option set', option);
			this.option = option;
			return option;
		},
		initChartDevice(slaDataByDevice) {
			let values = {};
			let days = {};
			console.debug('debug init chart device', slaDataByDevice);
			let allDates = [];
			slaDataByDevice.forEach((deviceGroup) => {
				days[deviceGroup.pmdatasourceinstanceid] = [];
				values[deviceGroup.pmdatasourceinstanceid] = [];
				deviceGroup.group.forEach((dateGroup) => {
					if (!allDates.includes(dateGroup.datevalue)) allDates.push(dateGroup.datevalue);
					days[deviceGroup.pmdatasourceinstanceid].push(dateGroup.datevalue);
					let totalDateOk = 0;
					let totalDateError = 0;
					dateGroup.group.forEach((sla) => {
						if (sla.docsok + sla.docserror > 0) {
							totalDateOk += sla.docsok;
							totalDateError += sla.docserror;
						}
					});
					if (totalDateOk + totalDateError > 0) {
						let percent = (totalDateOk / (totalDateOk + totalDateError)) * 100;
						values[deviceGroup.pmdatasourceinstanceid].push(percent.toFixed(2));
					} else {
						values[deviceGroup.pmdatasourceinstanceid].push(0);
					}
				});
			});
			slaDataByDevice.forEach((deviceGroup) => {
				if (allDates.length != days[deviceGroup.pmdatasourceinstanceid].length) {
					allDates.forEach((date, i) => {
						if (!days[deviceGroup.pmdatasourceinstanceid].includes(date)) {
							days[deviceGroup.pmdatasourceinstanceid] = days[deviceGroup.pmdatasourceinstanceid].toSpliced(i, 0, date);
							values[deviceGroup.pmdatasourceinstanceid] = values[deviceGroup.pmdatasourceinstanceid].toSpliced(i, 0, null);
						}
					});
				}
			});

			let option = {};
			let groupTitles = [];
			let data = {};
			let series = [];
			let yAxis = [];
			let tooltip = {};

			slaDataByDevice.forEach((deviceGroup) => {
				data = { values: values[deviceGroup.pmdatasourceinstanceid], days: days[deviceGroup.pmdatasourceinstanceid] };
				groupTitles.push(deviceGroup.devicecode);

				series.push({
					name: deviceGroup.devicecode,
					id: deviceGroup.devicecode + '_' + deviceGroup.pmdatasourceinstanceid,
					type: 'line',
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score} %'
					},
					data: data.values
				});
			});
			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				formatter: this.setTooltipFormatter(),
				valueFormatter: (value) => (value != null ? value + '%' : '- %')
			};
			//console.log('debug legend', groupTitles);
			option.legend = { data: groupTitles };
			if (groupTitles.length > 10) option.legend.right = '8%';
			option['series'] = series;
			//option['xAxis'] = xAxis;
			option['xAxis'] = {
				type: 'category',
				show: true,
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: allDates
			};

			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent('%');
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['grid'] = {
				top: groupTitles.length > 10 ? '20%' : '10%'
			};
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			////console.log('debug aqi option', option, JSON.stringify(option), store.getters.getParameterStatus);
			console.log('test: option set', option);
			//option && this.chartDevice.setOption(option);
			this.option = option;
			return option;
		},
		initChartWarning(slaData) {
			console.info('initChartWarning: sla data debug', slaData);
			let valuesValid = [];
			let valuesWarning = [];
			let days = [];
			let globalOkCount = 0;
			let globalWarningCount = 0;
			if (slaData.length > 0) {
				slaData.forEach((dateGroup) => {
					days.push(dateGroup.datevalue);
					let totalDateOk = 0;
					let totalDateWarning = 0;
					dateGroup.group.forEach((sla) => {
						if (sla.docsok + sla.docserror > 0) {
							totalDateOk += sla.docsok;
							totalDateWarning += sla.docswarning;
						}
					});
					if (totalDateOk + totalDateWarning > 0) {
						valuesValid.push(totalDateOk - totalDateWarning);
						valuesWarning.push(totalDateWarning);
					} else {
						valuesValid.push(0);
						valuesWarning.push(0);
					}
					globalOkCount += totalDateOk;
					globalWarningCount += totalDateWarning;
				});
			}

			if (this.chartWarning) this.chartWarning.dispose();
			this.chartWarning = null;
			let option = {};

			option = {
				legend: {},
				tooltip: {
					trigger: 'axis',
					showContent: true,
					valueFormatter: (value) => value
					//formatter: '{a}</br><ul><li>{b}: <b>{d}%</b></li></ul>',
				},
				dataset: {
					source: [days, valuesValid, valuesWarning]
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: days
				},
				yAxis: { gridIndex: 0 },
				grid: { top: '55%' },
				series: [
					{
						name: 'Documents',
						type: 'pie',
						radius: '35%',
						color: ['#36BA61', '#FCD37F'],
						center: ['50%', '30%'],
						data: [
							{ value: globalOkCount - globalWarningCount, name: 'Valid Data' },
							{ value: globalWarningCount, name: 'Contains Warning(s)' }
						],
						label: {
							show: true,
							formatter: '{b}: {d}%',
							color: '#414141'
						},
						labelLine: {
							smooth: 0.2,
							length: 15,
							length2: 20
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						animationType: 'scale',
						animationEasing: 'elasticOut'
					}
				]
			};
			option.series.push(
				{
					name: 'Valid Data',
					label: {
						show: false,
						formatter: '{@score}'
					},
					type: 'bar',
					barGap: 0,
					seriesLayoutBy: 'row',
					emphasis: { focus: 'series' },
					color: '#36BA61',
					data: valuesValid
				},
				{
					name: 'Contains Warning(s)',
					label: {
						show: false,
						formatter: '{@score}'
					},
					type: 'bar',
					barGap: 0,
					seriesLayoutBy: 'row',
					emphasis: { focus: 'series' },
					color: '#FCD37F',
					data: valuesWarning
				}
			);
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			////console.log('debug aqi option', option, JSON.stringify(option), store.getters.getParameterStatus);
			console.log('test: option set', option);

			//option && this.chartWarning.setOption(option);
			this.option = option;
			return option;
		},
		initChartWarningTypeV2(slaData) {
			let warningTypesGlobal = {};
			let warningTypesTooltip = {};
			let warningTypesLegend = {};
			let allDates = [];
			let days = {};
			let values = {};
			let colors = {};

			const customData = [];
			const legendData = [];
			const dataList = [];
			legendData.push('Trend');

			slaData.forEach((dateGroup, index) => {
				allDates.push(dateGroup.datevalue);
				dateGroup.group.forEach((sla) => {
					if (sla.warningtypes) {
						let warningTypes = JSON.parse(sla.warningtypes);
						Object.keys(warningTypes).forEach((warningKey) => {
							if (!Object.hasOwn(warningTypesGlobal, warningKey)) {
								warningTypesGlobal[warningKey] = warningTypes[warningKey];
							} else {
								warningTypesGlobal[warningKey] += warningTypes[warningKey];
							}
							if (!days[warningKey]) {
								days[warningKey] = [dateGroup.datevalue];
							} else if (!days[warningKey].includes(dateGroup.datevalue)) {
								days[warningKey].push(dateGroup.datevalue);
								values[warningKey].push(warningTypes[warningKey]);
							}

							if (!values[warningKey]) {
								values[warningKey] = [warningTypes[warningKey]];
							} else if (values[warningKey][index]) {
								values[warningKey][index] += warningTypes[warningKey];
							}
						});
					}
				});
			});

			let dataWarning = [];
			Object.keys(warningTypesGlobal).forEach((key, i) => {
				let errorCode = getErrorCode(key, this.$store.getters.getErrorCodes);
				dataWarning.push({
					value: warningTypesGlobal[key],
					name: key,
					tooltip: {
						trigger: 'item',
						formatter: '{a}</br><ul><li>' + (errorCode.legend ?? key) + ': <b>{d}%</b></li></ul>'
					}
				});
				colors[key] = errorCode.color ? errorCode.color : i > 8 ? this.palette[i - 8] : this.palette[i];
				warningTypesTooltip[key] = errorCode.message;
				warningTypesLegend[key] = errorCode.legend;
				legendData.push(key);

				allDates.forEach((date, i) => {
					if (!days[key].includes(date)) {
						days[key] = days[key].toSpliced(i, 0, date);
						values[key] = values[key].toSpliced(i, 0, 0);
					}
				});
			});

			for (let i = 0; i < allDates.length; i++) {
				var customVal = [i];
				customData.push(customVal);
				Object.values(values).forEach((value) => {
					customVal.push(value[i]);
				});
			}

			const encodeY = [];
			for (let i = 0; i < allDates.length; i++) {
				dataList.push([]);
				encodeY.push(1 + i);
			}
			let option = {
				legend: {
					data: legendData,
					formatter: (name) => warningTypesLegend[name] ?? name,
					tooltip: {
						trigger: 'item',
						show: true,
						formatter: (params) => warningTypesTooltip[params.name]
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					showContent: true
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: allDates
				},
				yAxis: {},
				grid: { top: '55%' },
				series: [
					{
						type: 'custom',
						name: 'Trend',
						renderItem: function (params, api) {
							var xValue = api.value(0);
							var currentSeriesIndices = api.currentSeriesIndices();
							var barLayout = api.barLayout({
								barGap: '30%',
								barCategoryGap: '20%',
								count: currentSeriesIndices.length - 1
							});
							var points = [];
							for (var i = 0; i < currentSeriesIndices.length; i++) {
								var seriesIndex = currentSeriesIndices[i];
								if (seriesIndex !== params.seriesIndex) {
									var point = api.coord([xValue, api.value(seriesIndex)]);
									point[0] += barLayout[i - 1].offsetCenter;
									point[1] -= 20;
									points.push(point);
								}
							}
							var style = api.style({
								stroke: api.visual('color'),
								fill: 'none'
							});
							return {
								type: 'polyline',
								shape: {
									points: points
								},
								style: style
							};
						},
						itemStyle: {
							borderWidth: 2
						},
						encode: {
							x: 0,
							y: encodeY
						},
						data: customData,
						z: 100
					},
					{
						name: 'Warning type',
						type: 'pie',
						radius: '35%',
						center: ['50%', '30%'],
						color: Object.values(colors),
						data: dataWarning,
						label: {
							show: true,
							color: '#414141',
							formatter: ({ name }) => warningTypesLegend[name] ?? name
						},
						labelLine: {
							smooth: 0.2,
							length: 15,
							length2: 20
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						animationType: 'scale',
						animationEasing: 'elasticOut'
					}
				]
			};
			Object.keys(warningTypesGlobal).forEach((key) => {
				option.series.push({
					name: key,
					id: key,
					label: {
						show: false,
						formatter: '{@score}'
					},
					color: colors[key],
					type: 'bar',
					barGap: 0,
					emphasis: { focus: 'series' },
					seriesLayoutBy: 'row',
					data: values[key]
				});
			});

			// Add event listener to update trend line when series are toggled
			this.chartWarningType.on(
				'legendselectchanged',
				function (params) {
					const selected = params.selected;
					const newCustomData = customData.map((data, index) => {
						const newData = [data[0]];
						Object.keys(selected).forEach((key) => {
							if (selected[key] && key !== 'Trend') {
								newData.push(values[key][index]);
							}
						});
						return newData;
					});
					option.series[0].data = newCustomData;
					this.chartWarningType.setOption(option);
				}.bind(this)
			);
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			this.option = option;
			return option;
		},
		initChartWarningType(slaData) {
			let warningTypesGlobal = {};
			let warningTypesTooltip = {};
			let warningTypesLegend = {};
			let days = [];
			let values = {};
			let colors = {};
			console.info('info sladata', slaData);
			if (slaData.length > 0) {
				slaData.forEach((dateGroup, index) => {
					days.push(dateGroup.datevalue);
					dateGroup.group.forEach((sla) => {
						if (sla.warningtypes) {
							let warningTypes = JSON.parse(sla.warningtypes);
							Object.keys(warningTypes).forEach((warningKey) => {
								if (!Object.hasOwn(values, warningKey)) {
									values[warningKey] = [warningTypes[warningKey]];
								} else if (typeof values[warningKey][index] === 'undefined') {
									values[warningKey].push(warningTypes[warningKey]);
								} else {
									values[warningKey][index] += warningTypes[warningKey];
								}

								if (!Object.hasOwn(warningTypesGlobal, warningKey)) {
									warningTypesGlobal[warningKey] = warningTypes[warningKey];
								} else {
									warningTypesGlobal[warningKey] += warningTypes[warningKey];
								}
							});
						}
					});
				});
			}
			console.debug('debug values', values);
			let dataWarning = [];
			Object.keys(warningTypesGlobal).forEach((key, i) => {
				let errorCode = getErrorCode(key, this.$store.getters.getErrorCodes);
				dataWarning.push({
					value: warningTypesGlobal[key],
					name: key,
					tooltip: {
						trigger: 'item',
						formatter: '{a}</br><ul><li>' + (errorCode.legend ?? key) + ': <b>{d}%</b></li></ul>'
					}
				});
				colors[key] = errorCode.color ? errorCode.color : i > 8 ? this.palette[i - 8] : this.palette[i];
				warningTypesTooltip[key] = errorCode.message;
				warningTypesLegend[key] = errorCode.legend;
			});

			let option = {};
			option = {
				legend: {
					formatter: (name) => warningTypesLegend[name] ?? name,
					tooltip: {
						trigger: 'item',
						show: true,
						formatter: (params) => warningTypesTooltip[params.name]
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					showContent: true,
					valueFormatter: (value) => value
					//formatter: '{a}</br><ul><li>{b}: <b>{d}%</b></li></ul>',
				},
				dataset: {
					source: { days: days, ...values }
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: days
				},
				yAxis: { gridIndex: 0 },
				grid: { top: '55%' },
				series: [
					{
						name: 'Warning type',
						type: 'pie',
						radius: '35%',
						center: ['50%', '30%'],
						color: Object.values(colors),
						data: dataWarning,
						label: {
							show: true,
							color: '#414141',
							formatter: ({ name }) => warningTypesLegend[name] ?? name
						},
						labelLine: {
							smooth: 0.2,
							length: 15,
							length2: 20
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						animationType: 'scale',
						animationEasing: 'elasticOut'
					}
				]
			};
			const orderedKeys = Object.keys(warningTypesGlobal)
				.sort()
				.reduce((obj, key) => {
					obj[key] = warningTypesGlobal[key];
					return obj;
				}, {});

			Object.keys(orderedKeys).forEach((key) => {
				option.series.push({
					name: key,
					label: {
						show: false,
						formatter: '{@score}'
					},
					color: colors[key],
					type: 'bar',
					barGap: 0,
					emphasis: { focus: 'series' },
					seriesLayoutBy: 'row',
					data: values[key]
				});
			});
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			console.log('test: option set', option);
			this.option = option;
			return option;
		},
		initChartFrequency(slaData) {
			let valuesRecieved = [];
			let valuesExpected = [];
			let days = [];
			slaData.forEach((dateGroup) => {
				days.push(dateGroup.datevalue);
				let totalDateOk = 0;
				let totalDateError = 0;
				let totalDateExpected = 0;

				dateGroup.group.forEach((sla) => {
					if (sla.docsok + sla.docserror > 0) {
						totalDateOk += sla.docsok;
						totalDateError += sla.docserror;
					}
					totalDateExpected += sla.docsexpectedbyfrequency;
					//console.debug('debug: expected', sla.devicecode, sla.docsexpectedbyfrequency);
				});
				//console.debug('debug: expected date', dateGroup.datevalue, totalDateExpected);
				valuesRecieved.push(totalDateOk + totalDateError);
				valuesExpected.push(totalDateExpected);
			});

			let option = {};

			//console.warn('log: chart option', option);

			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let tooltip = {};

			data = {
				valuesExpected: valuesExpected,
				valuesRecieved: valuesRecieved,
				days: days
			};

			series.push(
				{
					name: 'Expected',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesExpected
				},
				{
					name: 'Received',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesRecieved
				}
			);

			axis++;

			xAxis.push({
				type: 'category',
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: data.days
			});

			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				valueFormatter: (value) => (value ? value : '-')
			};

			option['legend'] = {};
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			console.log('test: option set', option);
			this.option = option;
			return option;
		},
		initChartFrequencyArea(slaDataArea, area) {
			this.areaFrequencyTitle = area ? area.name : null;
			let valuesRecieved = [];
			let valuesExpected = [];
			let days = [];
			console.debug('debug init chart freq area', slaDataArea, area);
			if (slaDataArea) {
				slaDataArea.group.forEach((dateGroup) => {
					days.push(dateGroup.datevalue);
					let totalDateOk = 0;
					let totalDateError = 0;
					let totalDateExpected = 0;
					dateGroup.group.forEach((sla) => {
						if (sla.docsok + sla.docserror > 0) {
							totalDateOk += sla.docsok;
							totalDateError += sla.docserror;
						}
						totalDateExpected += sla.docsexpectedbyfrequency;
					});
					valuesRecieved.push(totalDateOk + totalDateError);
					valuesExpected.push(totalDateExpected);
				});
			}

			let option = {};
			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let tooltip = {};

			data = {
				valuesExpected: valuesExpected,
				valuesRecieved: valuesRecieved,
				days: days
			};

			series.push(
				{
					name: 'Expected',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesExpected
				},
				{
					name: 'Received',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesRecieved
				}
			);
			axis++;
			xAxis.push({
				type: 'category',
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: data.days
			});

			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				valueFormatter: (value) => (value ? value : '-')
			};

			option['legend'] = {};
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			console.log('test: option set area freq', area, option);
			this.option = option;
			return option;
		},
		initChartFrequencyDatasource(slaDataDasource, datasource) {
			this.datasourceFrequencyTitle = datasource ? datasource.name : null;
			let valuesRecieved = [];
			let valuesExpected = [];
			let days = [];
			console.debug('debug init chart freq datasource', slaDataDasource, datasource, this.userProperties);
			if (slaDataDasource) {
				slaDataDasource.group.forEach((dateGroup) => {
					days.push(dateGroup.datevalue);
					let totalDateOk = 0;
					let totalDateError = 0;
					let totalDateExpected = 0;
					dateGroup.group.forEach((sla) => {
						if (sla.docsok + sla.docserror > 0) {
							totalDateOk += sla.docsok;
							totalDateError += sla.docserror;
						}
						totalDateExpected += sla.docsexpectedbyfrequency;
					});
					valuesRecieved.push(totalDateOk + totalDateError);
					valuesExpected.push(totalDateExpected);
				});
			}

			let option = {};
			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let tooltip = {};

			data = {
				valuesExpected: valuesExpected,
				valuesRecieved: valuesRecieved,
				days: days
			};

			series.push(
				{
					name: 'Expected',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesExpected
				},
				{
					name: 'Received',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesRecieved
				}
			);

			axis++;

			xAxis.push({
				type: 'category',
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: data.days
			});

			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				valueFormatter: (value) => (value ? value : '-')
			};

			option['legend'] = {};
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			////console.log('debug aqi option', option, JSON.stringify(option), store.getters.getParameterStatus);
			console.log('test: option set', option);

			//option && this.chartFrequency.setOption(option);
			this.option = option;
			return option;
		},
		initChartFrequencyDevice(slaDataDevice, device) {
			this.deviceFrequencyTitle = device ? device.pmdatasourcetypename + ': ' + device.code : null;
			let valuesRecieved = [];
			let valuesExpected = [];
			let days = [];
			console.debug('debug init chart freq device', slaDataDevice, device);
			if (slaDataDevice) {
				slaDataDevice.group.forEach((dateGroup) => {
					days.push(dateGroup.datevalue);
					let totalDateOk = 0;
					let totalDateError = 0;
					let totalDateExpected = 0;
					dateGroup.group.forEach((sla) => {
						if (sla.docsok + sla.docserror > 0) {
							totalDateOk += sla.docsok;
							totalDateError += sla.docserror;
						}
						totalDateExpected += sla.docsexpectedbyfrequency;
					});
					valuesRecieved.push(totalDateOk + totalDateError);
					valuesExpected.push(totalDateExpected);
				});
			}

			let option = {};
			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let tooltip = {};

			data = {
				valuesExpected: valuesExpected,
				valuesRecieved: valuesRecieved,
				days: days
			};

			series.push(
				{
					name: 'Expected',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesExpected
				},
				{
					name: 'Received',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					label: {
						formatter: '{@score}'
					},
					data: data.valuesRecieved
				}
			);

			axis++;

			xAxis.push({
				type: 'category',
				axisTick: {
					alignWithLabel: true
				},
				axisLine: {
					onZero: false
				},
				data: data.days
			});

			yAxis = [{ type: 'value' }];
			tooltip = {
				trigger: 'axis',
				valueFormatter: (value) => (value ? value : '-')
			};

			option['legend'] = {};
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['tooltip'] = tooltip;
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			console.log('test: option set', option);
			this.option = option;
			return option;
		},
		initChartDataQuality(errorTypesByDate) {
			let warningTypesGlobal = {};
			let warningTypesTooltip = {};
			let warningTypesLegend = {};
			let days = [];
			let values = {};
			let colors = {};
			let dataWarning = [];

			console.info('info errorTypesByDate', errorTypesByDate);
			Object.keys(errorTypesByDate).forEach((dateKey, index) => {
				days.push(getDateByFormat(new Date(dateKey).valueOf(), this.userProperties.constants, true));
				Object.keys(errorTypesByDate[dateKey]).forEach((errorKey) => {
					if (!Object.hasOwn(values, errorKey)) {
						values[errorKey] = [errorTypesByDate[dateKey][errorKey]];
					} else if (typeof values[errorKey][index] === 'undefined') {
						values[errorKey].push(errorTypesByDate[dateKey][errorKey]);
					} else {
						values[errorKey][index] += errorTypesByDate[dateKey][errorKey];
					}

					if (!Object.hasOwn(warningTypesGlobal, errorKey)) {
						warningTypesGlobal[errorKey] = errorTypesByDate[dateKey][errorKey];
					} else {
						warningTypesGlobal[errorKey] += errorTypesByDate[dateKey][errorKey];
					}
				});
			});
			console.debug('debug days and values', days, values);

			Object.keys(warningTypesGlobal).forEach((key, i) => {
				let errorCode = getErrorCode(key, this.$store.getters.getErrorCodes);
				dataWarning.push({
					value: warningTypesGlobal[key],
					name: key,
					tooltip: {
						trigger: 'item',
						formatter: '{a}</br><ul><li>' + (errorCode.legend ?? key) + ': <b>{d}%</b></li></ul>'
					}
				});
				colors[key] = errorCode.color ? errorCode.color : i > 8 ? this.palette[i - 8] : this.palette[i];
				warningTypesTooltip[key] = errorCode.message;
				warningTypesLegend[key] = errorCode.legend;
			});

			let option = {};
			option = {
				legend: {
					formatter: (name) => warningTypesLegend[name] ?? name,
					tooltip: {
						trigger: 'item',
						show: true,
						formatter: (params) => warningTypesTooltip[params.name]
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					showContent: true,
					valueFormatter: (value) => value
				},
				dataset: {
					source: { days: days, ...values }
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: days
				},
				yAxis: { gridIndex: 0 },
				grid: { top: '55%' },
				series: [
					{
						name: 'Error type',
						type: 'pie',
						radius: '35%',
						center: ['50%', '30%'],
						color: Object.values(colors),
						data: dataWarning,
						label: {
							show: true,
							color: '#414141',
							formatter: ({ name }) => warningTypesLegend[name] ?? name
						},
						labelLine: {
							smooth: 0.2,
							length: 15,
							length2: 20
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						animationType: 'scale',
						animationEasing: 'elasticOut'
					}
				]
			};
			const orderedKeys = Object.keys(warningTypesGlobal)
				.sort()
				.reduce((obj, key) => {
					obj[key] = warningTypesGlobal[key];
					return obj;
				}, {});

			Object.keys(orderedKeys).forEach((key) => {
				option.series.push({
					name: key,
					label: {
						show: false,
						formatter: '{@score}'
					},
					color: colors[key],
					type: 'bar',
					barGap: 0,
					emphasis: { focus: 'series' },
					seriesLayoutBy: 'row',
					data: values[key]
				});
			});
			option['toolbox'] = this.addToolbox();
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
			option['dataZoom'] = this.addDataZoomXY();
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};

			console.log('test: option set', option);
			this.option = option;
			return option;
		},
		initChartSummary(slaDevice, chartWidth = 100, unit = '', slaDefined = true) {
			let totalOk = 0;
			let totalError = 0;
			let teoricSla = slaDevice[0].devicesla;
			let noData = false;
			slaDevice.forEach((sla) => {
				totalOk += sla.docsok;
				totalError += sla.docserror;
			});
			let chartOk = 0;
			let chartError = 0;
			if (totalOk + totalError > 0) chartOk = parseFloat(((totalOk / (totalOk + totalError)) * 100).toFixed(2));
			else noData = true;
			if (totalError > 0) chartError = parseFloat(((totalError / (totalOk + totalError)) * 100).toFixed(2));
			console.debug('debug init chart summary', slaDevice, totalOk, totalError, chartOk, chartError);
			let option = {
				tooltip: {
					trigger: 'axis',
					valueFormatter: (value) => value + unit,
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {},
				grid: {
					left: 'center',
					bottom: '3%',
					width: chartWidth,
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						show: true,
						data: ['']
					}
				],
				yAxis: [
					{
						min: 0,
						max: 100,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						}
					}
				],
				textStyle: {
					fontFamily: 'Montserrat, sans-serif',
					fontSize: 14
				},
				series: []
			};
			if (chartOk > 0) {
				option.series.push({
					name: slaDefined ? 'Ok' : this.$t('common.notDefined'),
					type: 'bar',
					stack: 'Ad',
					barWidth: 50,
					itemStyle: {
						color: slaDefined ? '#36BA61' : '#b0b6c2'
					},
					emphasis: {
						focus: 'series'
					},
					label: {
						show: true,
						position: 'inside',
						formatter: '{c}' + unit,
						color: '#000',
						fontWeight: 600
					},
					data: [chartOk],
					markLine: {
						data: [
							{
								name: 'SLA',
								yAxis: teoricSla,
								label: {
									position: 'end',
									formatter: '{c}' + unit
								},
								lineStyle: { color: '#5470c6', width: 3 }
							}
						]
					}
				});
			}
			if (chartError > 0) {
				option.series.push({
					name: 'Error',
					type: 'bar',
					stack: 'Ad',
					barWidth: 50,
					itemStyle: {
						color: '#ED5E4A'
					},
					emphasis: {
						focus: 'series'
					},
					label: {
						show: true,
						position: 'inside',
						formatter: '{c}%',
						color: '#000',
						fontWeight: 600
					},
					data: [chartError]
				});
			}
			if (noData) {
				option.title = {
					subtext: this.$t('common.noData'),
					subtextStyle: { fontWeight: 600, fontSize: 14 },
					left: '42%',
					top: 'center'
				};
			}

			//console.log('bar realtime option', option, JSON.stringify(option));
			return option;
		},
		initChartFrequencySummary(slaDevice, chartWidth = 100, unit = '', dpDefined = true) {
			let totalOk = 0;
			let totalError = 0;
			let totalExpected = 0;
			let noData = false;
			slaDevice.forEach((sla) => {
				totalOk += sla.docsok;
				totalError += sla.docserror;
				totalExpected += sla.docsexpectedbyfrequency;
			});

			let chartReceived = 0;
			if (totalOk + totalError > 0) chartReceived = totalOk + totalError;
			else noData = true;
			let option;

			option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {},
				grid: {
					left: 'center',
					bottom: '3%',
					width: chartWidth,
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						show: true,
						data: ['']
					}
				],
				yAxis: [
					{
						min: 0,
						max: totalExpected,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						}
					}
				],
				textStyle: {
					fontFamily: 'Montserrat, sans-serif',
					fontSize: 14
				},
				series: [
					{
						name: dpDefined ? 'Received' : this.$t('common.notDefined'),
						type: 'bar',
						stack: 'Ad',
						barWidth: 50,
						itemStyle: {
							color: dpDefined ? '#36BA61' : '#b0b6c2'
						},
						emphasis: {
							focus: 'series'
						},
						label: {
							show: true,
							position: 'inside',
							formatter: '{c}' + unit,
							color: '#000',
							fontWeight: 600
						},
						markLine: {
							data: [
								{
									name: 'Expected',
									yAxis: totalExpected,
									label: {
										position: 'end',
										formatter: '{c}' + unit
									},
									lineStyle: { color: '#5470c6', width: 3 }
								}
							]
						},
						data: [chartReceived]
					}
				]
			};
			if (noData) {
				option.title = {
					subtext: this.$t('common.noData'),
					subtextStyle: { fontWeight: 600, fontSize: 14 },
					left: '42%',
					top: 'center'
				};
			}
			//console.log('bar realtime option', option, JSON.stringify(option));
			return option;
		},
		initChartErrorSummary(errorsDevice, chartWidth = 100) {
			//let warningTypesGlobal = {};
			let warningTypesTooltip = {};
			let warningTypesLegend = {};
			let colors = {};
			//let dataWarning = [];
			let totalErrors = 0;

			Object.keys(errorsDevice).forEach((key, i) => {
				let errorCode = getErrorCode(key, this.$store.getters.getErrorCodes);
				totalErrors += errorsDevice[key];
				/* dataWarning.push({
					value: warningTypesGlobal[key],
					name: key,
					tooltip: {
						trigger: 'item',
						formatter: '{a}</br><ul><li>' + (errorCode.legend ?? key) + ': <b>{d}%</b></li></ul>'
					}
				}); */
				colors[key] = errorCode.color ? errorCode.color : i > 8 ? this.palette[i - 8] : this.palette[i];
				warningTypesTooltip[key] = errorCode.message;
				warningTypesLegend[key] = errorCode.legend;
			});
			console.log('initChartErrorSummary', errorsDevice, Object.keys(errorsDevice), totalErrors);
			let option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					position: ['75%', '0%'],
					formatter: (params) => {
						let tooltip = '';
						params.forEach((param) => {
							const entry = param.marker + param.seriesName + ': ' + (param.value >= 0 ? '<b>' + param.value + '</b>' : '-');
							tooltip += entry + '<br>';
						});
						return tooltip;
					}
				},
				legend: { show: false },
				grid: {
					left: 'center',
					bottom: '3%',
					height: 180,
					width: chartWidth
				},
				xAxis: [
					{
						type: 'category',
						show: true,
						data: ['']
					}
				],
				yAxis: [
					{
						min: 0,
						max: totalErrors || 100,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						}
					}
				],
				textStyle: {
					fontFamily: 'Montserrat, sans-serif',
					fontSize: 14
				},
				series: []
			};
			Object.keys(errorsDevice).forEach((errorKey) => {
				option.series.push({
					name: warningTypesLegend[errorKey] || errorKey,
					id: errorKey,
					type: 'bar',
					stack: 'Ad',
					barWidth: 50,
					itemStyle: {
						color: colors[errorKey]
					},
					emphasis: {
						focus: 'series'
					},
					label: {
						show: true,
						position: 'inside',
						color: '#000',
						fontWeight: 600
					},
					data: [errorsDevice[errorKey]]
				});
			});

			//console.log('bar realtime option', option, JSON.stringify(option));
			return option;
		},
		initChartWarningSummary(slaWarnings, chartWidth = 100) {
			let warningTypesGlobal = {};
			let warningTypesTooltip = {};
			let warningTypesLegend = {};
			let colors = {};
			//let dataWarning = [];
			let totalErrors = 0;

			if (slaWarnings.length > 0) {
				slaWarnings.forEach((sla) => {
					if (sla.warningtypes) {
						let warningTypes = JSON.parse(sla.warningtypes);
						Object.keys(warningTypes).forEach((warningKey) => {
							if (!Object.hasOwn(warningTypesGlobal, warningKey)) {
								warningTypesGlobal[warningKey] = warningTypes[warningKey];
							} else {
								warningTypesGlobal[warningKey] += warningTypes[warningKey];
							}
						});
					}
				});
			}

			Object.keys(warningTypesGlobal).forEach((key, i) => {
				totalErrors += warningTypesGlobal[key];
				let errorCode = getErrorCode(key, this.$store.getters.getErrorCodes);
				colors[key] = errorCode.color ? errorCode.color : i > 8 ? this.palette[i - 8] : this.palette[i];
				warningTypesTooltip[key] = errorCode.message;
				warningTypesLegend[key] = errorCode.legend;
			});

			let option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					position: ['75%', '0%'],
					formatter: (params) => {
						let tooltip = '';
						params.forEach((param) => {
							const entry = param.marker + param.seriesName + ': ' + (param.value >= 0 ? '<b>' + param.value + '</b>' : '-');
							tooltip += entry + '<br>';
						});
						return tooltip;
					}
				},
				legend: { show: false },
				grid: {
					left: 'center',
					bottom: '3%',
					height: 180,
					width: chartWidth
				},
				xAxis: [
					{
						type: 'category',
						show: true,
						data: ['']
					}
				],
				yAxis: [
					{
						min: 0,
						max: totalErrors || 100,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						}
					}
				],
				textStyle: {
					fontFamily: 'Montserrat, sans-serif',
					fontSize: 14
				},
				series: []
			};
			Object.keys(warningTypesGlobal).forEach((warningKey) => {
				option.series.push({
					name: warningTypesLegend[warningKey] || warningKey,
					id: warningKey,
					type: 'bar',
					stack: 'Ad',
					barWidth: 50,
					itemStyle: {
						color: colors[warningKey]
					},
					emphasis: {
						focus: 'series'
					},
					label: {
						show: false,
						position: 'inside',
						color: '#000',
						fontWeight: 600
					},
					data: [warningTypesGlobal[warningKey]]
				});
			});

			//console.log('bar realtime option', option, JSON.stringify(option));
			return option;
		},
		addToolbox() {
			return {
				right: 10,
				feature: {
					restore: {
						title: this.$t('charts.restore'),
						show: 'true'
					},
					saveAsImage: {
						title: this.$t('charts.saveAsImage'),
						show: 'true'
					},
					dataView: {
						title: this.$t('charts.title'),
						lang: [this.$t('charts.dataView'), this.$t('charts.closeView'), this.$t('charts.updateData')],
						show: 'true',
						readOnly: 'true'
					}
				}
			};
		},
		addDataZoomXY() {
			return [
				{
					type: 'slider',
					xAxisIndex: 0,
					filterMode: 'true'
				},
				{
					type: 'slider',
					yAxisIndex: 0,
					filterMode: 'true'
				},
				{
					type: 'inside',
					xAxisIndex: 0,
					filterMode: 'none'
				},
				{
					type: 'inside',
					yAxisIndex: 0,
					filterMode: 'none'
				}
			];
		},
		setTooltipFormatter() {
			return (params) => {
				const totalSeries = params.length;
				if (totalSeries > 30) {
					const column1 = [];
					const column2 = [];
					const column3 = [];
					params.forEach((param, index) => {
						const entry = param.marker + param.seriesName + ': ' + (param.value >= 0 ? '<b>' + param.value + '%</b>' : '-');
						if (index < totalSeries / 3) {
							column1.push(entry);
						} else if (index < totalSeries * 0.67) {
							column2.push(entry);
						} else {
							column3.push(entry);
						}
					});
					return `<span>${params[0].name}</span><br><div style='display: flex; justify-content: space-between;'><div style='padding-right: 8px'>${column1.join('<br>')}</div><div style='padding-right: 8px'>${column2.join('<br>')}</div><div>${column3.join('<br>')}</div></div>`;
				} else if (totalSeries > 10) {
					const column1 = [];
					const column2 = [];
					params.forEach((param, index) => {
						const entry = param.marker + param.seriesName + ': ' + (param.value >= 0 ? '<b>' + param.value + '%</b>' : '-');
						if (index < totalSeries / 2) {
							column1.push(entry);
						} else {
							column2.push(entry);
						}
					});
					return `<span>${params[0].name}</span><br><div style='display: flex; justify-content: space-between;'><div style='padding-right: 8px'>${column1.join('<br>')}</div><div style='padding-right: 8px'>${column2.join('<br>')}</div></div>`;
				}
				return (
					'<span>' +
					params[0].name +
					'</span><br>' +
					params
						.map((param) => param.marker + param.seriesName + ': ' + (param.value >= 0 ? '<b>' + param.value + '%</b>' : '-'))
						.join('<br>')
				);
			};
		},
		setOptionToContent(unit = '') {
			return (opt) => {
				const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
				let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
				let headerRow = [this.$t('rasterTools.date')];
				optionSeries.forEach((serie) => headerRow.push(serie.name));
				let ws_data = [
					...categories.map((category, index) => {
						let row = [category];
						optionSeries.forEach((serie) => row.push(serie.data[index]));
						return row;
					})
				];
				let table = '<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">';
				headerRow.forEach((item) => (table += '<td class="px-1">' + item + '</td>'));
				table += '</tr>';

				ws_data.forEach((row) => {
					table += '<tr>';
					row.forEach((item, i) => (table += '<td>' + (i == 0 ? item : item && item >= 0 ? item + unit : '-') + '</td>'));
					table += '</tr>';
				});

				table += '</tbody></table>';
				return table;
			};
		},

		capitalize(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		updateCounters(slaData) {
			let globalOkCount = 0;
			let globalWarningCount = 0;
			let globalErrorCount = 0;
			let globalFrequencyCount = 0;
			slaData.forEach((dateGroup) => {
				let totalDateOk = 0;
				let totalDateWarning = 0;
				let totalDateError = 0;
				let totalDateFrequency = 0;
				dateGroup.group.forEach((sla) => {
					if (sla.docsok + sla.docserror > 0) {
						totalDateOk += sla.docsok;
						totalDateWarning += sla.docswarning;
						totalDateError += sla.docserror;
					}
					totalDateFrequency += sla.docsexpectedbyfrequency;
				});
				globalOkCount += totalDateOk;
				globalWarningCount += totalDateWarning;
				globalErrorCount += totalDateError;
				globalFrequencyCount += totalDateFrequency;
			});

			this.globalOk = globalOkCount;
			this.globalWarning = globalWarningCount;
			this.globalError = globalErrorCount;
			this.globalExpected = globalFrequencyCount;
		}
	}
};
